<template>
    <div>
        <div>
            <q-card class="my-card" flat id="q-content">
                <!--选择题-->
                <ChoiceQuestion v-if="$store.state.qusType === 1" :qusData="$store.state.qusData"></ChoiceQuestion>
                <!--多择题 || 判断题 -->
                <MultipleChoice v-if="$store.state.qusType === 2 " :qusData="$store.state.qusData"></MultipleChoice>
                <JudgmentQuestion v-if="$store.state.qusType === 3" :qusData="$store.state.qusData"></JudgmentQuestion>
                <!--填空题-->
                <supplementQuestion v-if="$store.state.qusType === 5" :qusData="$store.state.qusData"></supplementQuestion>
                <!--拖拽题-->
                <DragQuestion v-if="$store.state.qusType === 4" :qusData="$store.state.qusData"></DragQuestion>
            </q-card>
        </div>

    </div>
</template>

<script>
    import ChoiceQuestion from "../components/wrongQuestion/ChoiceQuestion";
    import MultipleChoice from "@/components/wrongQuestion/MultipleChoice"
    import DragQuestion from "@/components/wrongQuestion/DragQuestion"
    import supplementQuestion from "@/components/wrongQuestion/supplementQuestion"
    import JudgmentQuestion from "@/components/wrongQuestion/JudgmentQuestion"
    export default {
        name: "wrongQuestion",
        components:{
            ChoiceQuestion,
            MultipleChoice,
            DragQuestion,
            supplementQuestion,
            JudgmentQuestion
        },
        data(){
            return{
                current: 1,
                qusTab: true,
                max:0,

            }
        },


    }
</script>

<style scoped lang="less">
    #page-floot{
        position: absolute;
        height: 80px;
        width: 100%;
        bottom: 0;
        line-height: 40px;
        padding: 0 33px;
        display: flex;
        align-items: center;
        font-size: 18px;
        background: #ffffff;
    }
</style>